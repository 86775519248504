
const url = 'https://apidemo.easysheba.com';
const invUrl = "https://admin.easysheba.com";

// const invUrl = "http://localhost:3000";
// const url = 'http://localhost:8000'


export default url;

export{ invUrl };