import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Form1 } from '../../components/Form';
import { Input1, Textarea, Select, Option } from  '../../components/Input';
import url from '../../url';
import { Alert2, AlertContainer } from '../../components/Alert';

function AddSubService() {
  const [value, setValue] = useState({name:'', description:'', serviceId:'',});
  const [serviceGroup, setServiceGroup] = useState([]);
  const [selectSG, setSelectSG] = useState("");
  const [service, setService] = useState([]);
  const [alert, setAlert] = useState([]);
  const navigate = useNavigate();

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }


  useEffect(()=>{

    fetch(`${url}/service/search?page=1&limit=0&search=${selectSG}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{

      if(data.status === true){
          setService(data.result.data);
      }else{
          setService([])
      }
    });

  },[selectSG]);

  useEffect(()=>{

    fetch(`${url}/serviceGroup?page=1&limit=0`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
        if(data.status === true){
            setServiceGroup(data.result.data);
        }
    });

},[]);

  function send(){
    fetch(`${url}/subService`,{
      method:"POST",
      body: JSON.stringify(value),
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        navigate(-1);
      }else{
        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
      }
    });
  }


  return (
    <Form1 title="Add Sub Service">
        <div className= " fixed top-16 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
        <Input1 onChange={set} name="name" placeholder="Enter sub service name:" value={value.name} lavel="Sub service name:" />
        <Textarea onChange={set} name="description" placeholder="Enter sub service description:" value={value.description} lavel="Sub service description:" />
        <Select name="serviceGroupId" onChange={(e)=>setSelectSG(e.target.value)} value={selectSG}  id="serviceGroupId"  lavel="Select service group:">
          <Option value="" disable={true}>Select a service group</Option>
          {
              serviceGroup.map((data, index)=>{
                  return(
                      <Option key={index} value={data._id}>{data.name}</Option>
                  )
              })
          }
        </Select>
        <Select onChange={set}  name="serviceId" value={value.serviceId}  id="input4"  lavel="Select service :">
          <Option value="" disable={true}>Select a service</Option>
          {
              service.map((data, index)=>{
                  return(
                      <Option key={index} value={data._id}>{data.name}</Option>
                  )
              })
          }
        </Select>
        <Button1 click={send} name="Save" />
    </Form1>

  )
}

export default AddSubService;