import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Form1 } from '../../components/Form';
import { Input1 } from  '../../components/Input';
import url from '../../url';
import { Alert2, AlertContainer } from '../../components/Alert';

function AddPackagePhoto() {
  const [value, setValue] = useState({ file:{}});
  const [alert, setAlert] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();


  function icon(e){
    setValue({...value,[e.target.name]:e.target.files[0]});
  }

  function send(){


    const formData = new FormData();

    formData.append('photo',value.file);
    formData.append('package', id);

    fetch(`${url}/package/addPhoto/${id}`,{
      method:"POST",
      body:  formData,
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        navigate(-1);
      }else{
        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
      }
    });
  }


  return (
    <Form1 title="Add Photo">
        <div className= " fixed top-16 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
        <Input1 onChange={(e)=>icon(e)} type="file" name="file" lavel="Upload an icon." />
        <Button1 click={send} name="Save" />
    </Form1>

  )
}

export default AddPackagePhoto;