import React, {  useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Delete, Edit, View } from '../../components/Button';
import Filter from '../../components/Filter';
import { Option, Select } from '../../components/Input';
import Table, { Tr, Td } from '../../components/Table';
import Loader from '../../components/Loader';
import url from '../../url'
import { Alert2, AlertContainer } from '../../components/Alert';

function AllService() {

const [service, setService] = useState({data:[],next:{},previous:{}});
const [serviceGroup, setServiceGroup] = useState([]);
const [src, setSrc] = useState('');
const page = useSelector((state)=>state.page);
const limit = useSelector((state)=>state.limit);
const [loader, setLoader] = useState(true);
const [alert, setAlert] = useState([]);
const [select, setSelect] = useState("");




function delet(id){
    fetch(`${url}/service/${id}`,{method:'DELETE',mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
            if(src === ''){
                setLoader(true);
                fetch(`${url}/service?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                    setLoader(false);
                    if(data.status === true){
                      setService(data.result);
                    }else{
                        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
                    }
                });
            }else{
                setLoader(true);
                fetch(`${url}/service/search?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                    setLoader(false);
                    if(data.status === true){
                      setService(data.result);
                    }else{
                        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
                    }
                });
                 
            }
        }else{
            setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
        }
    });
}
useEffect(()=>{
    if(src === ''){
        setLoader(true);
        fetch(`${url}/service?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
            setLoader(false);
            if(data.status === true){;
                setService(data.result);
            }else{
                setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
            }
        });
    }

    return () => setService({data:[],next:{},previous:{}});

},[page, src, limit]);

useEffect(()=>{


    if(src !== ''){
        fetch(`${url}/service/search?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setService(data.result);
            }else{
                setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
            }
        });
    
    }

    return () => setService({data:[],next:{},previous:{}});
},[page, limit, src]);


useEffect(()=>{
    setLoader(true);
    fetch(`${url}/serviceGroup?page=1&limit=0`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
        setLoader(false);
        if(data.status === true){
            setServiceGroup(data.result.data);
        }else{
            setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
        }
    });

  },[]);

function search(e){
    setSrc(e.target.value);
}


useEffect(()=>{

    setLoader(true);
    fetch(`${url}/service/search?page=${page}&limit=${limit}&search=${select}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        setLoader(false);
        if(data.status === true){
            setService(data.result);
        }else{
            setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
            setService({data:[],next:{},previous:{}})
        }
    });
},[select]);

    function addForHome({id, e}){

        fetch(`${url}/service/${id}`,{
            method:"PATCH",
            body:  JSON.stringify({forHome:e.target.value}),
            credentials: 'include'
        }).then((data)=>data.json()).then((data)=>{

            if(data.status === true){
                setLoader(true);
                fetch(`${url}/service?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                    setLoader(false);
                    if(data.status === true){;
                        setService(data.result);
                    }else{
                        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
                    }
                });
            }else{
                setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
            }
        });

        
    }

    function addTranding({id, e}){

        fetch(`${url}/service/t/${id}`,{
            method:"PATCH",
            body:  JSON.stringify({tranding:e.target.value}),
            credentials: 'include'
        }).then((data)=>data.json()).then((data)=>{

            if(data.status === true){
                setLoader(true);
                fetch(`${url}/service?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                    setLoader(false);
                    if(data.status === true){;
                        setService(data.result);
                    }else{
                        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
                    }
                });
            }else{
                setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
            }
        });

        
    }

  return (<>{
    loader?<div className=' w-full flex justify-center mt-5 '><Loader /></div>:
      <div className=' w-max md:w-auto mx-auto'>
        <Filter >
            <div className=' w-80 '>
                <Select onChange={(e)=>{ setSelect(e.target.value) }} value={select}  name="serviceGroupId"  id="input4"  lavel="Select service group :">
                <Option value="" disable={true}>Select a service group</Option>
                <Option value="" >All service group</Option>
                {
                    serviceGroup.map((data, index)=>{
                        return(
                            <Option key={index} value={data._id}>{data.name}</Option>
                        )
                    })
                }
                </Select>
            </div>

        </Filter>
        <div className= " fixed top-32 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
        <Table to="/addService" name="Add Service" rowNames={["#","Name","Description","Service group","Image","Banner","For Your Home","Trending","Actions"]} srcVal={src} srcFunc={search} data={service} width='w-full'>
                    {
                        service.data.map(({_id, name, description, serviceGroup, image, banner, forHome, tranding}, index)=>{
                            return(
                                <Tr key={index}>
                                    <Td>{((service.previous.page*service.previous.limit)+1)+index}</Td>
                                    <Td>{name}</Td>
                                    <Td width='w-40'>{description}</Td>
                                    <Td>{serviceGroup.name}</Td>
                                    <Td width='w-40 '><img className=' w-20 text-center block mx-auto' src={image.img} alt="" /></Td>
                                    <Td width='w-40 '><img className=' w-20 text-center block mx-auto' src={banner.img} alt="" /></Td>
                                    <Td>
                                        <Select onChange={(e)=> addForHome({id:_id, e})} value={forHome}>
                                            {/* <Option value="off">For Home</Option> */}
                                            <Option value="off">OFF</Option>
                                            <Option value="on">ON</Option>
                                        </Select>
                                    </Td>
                                    <Td>
                                        <Select onChange={(e)=> addTranding({id:_id, e})} value={tranding}>
                                            <Option value="off">OFF</Option>
                                            <Option value="on">ON</Option>
                                        </Select>
                                    </Td>
                                    <Td>
                                        <View to={"/viewService/"+_id} />
                                        <Edit to={"/editService/"+_id} />
                                        <Delete id={_id} click={delet} />
                                    </Td>
                                </Tr>
                            );
                        })
                    }
                    
        </Table>
    </div>
}</>)
}
export default AllService;