import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button1 } from '../../../components/Button';
import { Form1 } from '../../../components/Form';
import { Textarea } from  '../../../components/Input';
import url from '../../../url';
import { Alert2, AlertContainer } from '../../../components/Alert';

function AddInclude() {
  const [value, setValue] = useState({name:'', serviceId:'',});
  const navigate = useNavigate();
  const [alert, setAlert] = useState([]);

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  const { id } = useParams();


  useEffect(()=>{

    setValue({...value, serviceId:id});

  },[id]);

  function send(){
    fetch(`${url}/include`,{
      method:"POST",
      body: JSON.stringify(value),
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        navigate(-1);
      }else{
        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
      }
    });
  }


  return (
    <Form1 title="Add Include">
        <div className= " fixed top-16 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
        <Textarea onChange={set} name="name" placeholder="Enter included:" value={value.description} lavel="Included:" />
        <Button1 click={send} name="Save" />
    </Form1>

  )
}

export default AddInclude;