import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button1 } from '../../../components/Button';
import { Form1 } from '../../../components/Form';
import { Input1} from  '../../../components/Input';
import url from '../../../url';

function EditBlogCategory({props}) {
  const [value, setValue] = useState({name:''});
  const navigate = useNavigate();
  const {id} = useParams();

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  function send(){
    fetch(`${url}/blogCategory/${id}`,{
      method:"PUT",
      body: JSON.stringify(value),
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        navigate(-1);
      }
    });
  }

  useEffect(()=>{

    fetch(`${url}/blogCategory/${id}`,{
        method:"GET",
        credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
          setValue({name: data.data[0].name, description: data.data[0].description});
        }
    });

  },[id]);




  return (
    <Form1 title="Edit blog category">
        <Input1 onChange={set} name="name" placeholder="Enter blog category name:" value={value.name} lavel="Blog category name:" />
        <Button1 click={send} name="Save" />
    </Form1>

  )
}
export default EditBlogCategory;