import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Form1 } from '../../components/Form';
import { Input1, Textarea } from  '../../components/Input';
import url from '../../url';
import { Alert2, AlertContainer } from '../../components/Alert';

function AddServicGroup() {
  const [value, setValue] = useState({name:'', description:'', file:{}});
  const [alert, setAlert] = useState([]);
  const navigate = useNavigate();

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  function icon(e){
    setValue({...value,[e.target.name]:e.target.files[0]});
  }

  function send(){


    const formData = new FormData();

    formData.append('name',value.name);
    formData.append('description',value.description);
    formData.append('photo',value.file);

    fetch(`${url}/serviceGroup`,{
      method:"POST",
      body:  formData,
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        navigate(-1);
      }else{
        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
      }
    });
  }


  return (
    <Form1 title="Add Service Group">
        <div className= " fixed top-16 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
        <Input1 onChange={set} name="name" placeholder="Enter service group name:" value={value.name} lavel="Service group name:" />
        <Textarea onChange={set} name="description" placeholder="Enter service group description:" value={value.description} lavel="Service group description:" />
        <Input1 onChange={(e)=>icon(e)} type="file" name="file" lavel="Upload an icon." />
        <Button1 click={send} name="Save" />
    </Form1>

  )
}

export default AddServicGroup;