import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import url from '../../url';

function ViewPackage() {

    const [service, setService] = useState({});
    const { id } = useParams();


    useEffect(()=>{
        fetch(`${url}/package/${id}`).then((data)=> data.json()).then(data=>{
            setService(data.data)
        })
    },[id]);

  return (
    <div className=' w-full flex flex-col items-center justify-center p-10 '>

        <div className=' w-full border-2 mt-6 rounded-md border-dark-blue-1'>
            <div className=' w-full h-16 pr-4 flex  items-center justify-end bg-dark-blue-1'> <Link to={"/package/addDetails/"+id} ><button className=" rounded-3xl bg-cyan-200 hover:bg-dark-blue-1 border-2 border-cyan-200 hover:text-white   float-right mt-2 mr-2 px-2 py-1">Add Details</button></Link></div>
            <div className=' p-4'  dangerouslySetInnerHTML={{ __html: service.description }}>

            </div>
        </div>
    </div>
  )
}

export default ViewPackage;