import React, {  useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Delete, Edit, View } from '../../../components/Button';
import Table, { Tr, Td, Img } from '../../../components/Table';
import Loader from '../../../components/Loader';
import url from '../../../url'
import { Select, Option } from '../../../components/Input';

function AllBlog() {

const [blog, setBlog] = useState({data:[],next:{},previous:{}});
const [src, setSrc] = useState('');
const page = useSelector((state)=>state.page);
const limit = useSelector((state)=>state.limit);
const [loader, setLoader] = useState(true);



function delet(id){
    fetch(`${url}/blog/${id}`,{method:'DELETE',mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
            if(src === ''){
                setLoader(true);
                fetch(`${url}/blog?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
                    setLoader(false);
                    if(data.status === true){
                        setBlog(data.result);
                    }
                });
            }else{
                setLoader(true);
                fetch(`${url}/blog/search?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
                    setLoader(false);
                    if(data.status === true){
                        setBlog(data.result);
                    }
                });
                 
            }
        }else{

        }
    });
}
useEffect(()=>{
    if(src === ''){
        setLoader(true);
        fetch(`${url}/blog?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        
            setLoader(false);
            if(data.status === true){
                setBlog(data.result);
            }
        });
    }

    return () => setBlog({data:[],next:{},previous:{}});

},[page,src, limit]);

useEffect(()=>{
    if(src !== ''){
        fetch(`${url}/blog/search?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setBlog(data.result);
            }
        });
    
    }

    return () => setBlog({data:[],next:{},previous:{}});
},[page, limit, src]);

function search(e){
    setSrc(e.target.value);
}

function setForHome(e){

    fetch(`${url}/blog/forHome/${e.target.id}`,{
        method:"POST",
        body: JSON.stringify({type:e.target.value}),
        credentials: 'include'
        }).then((data)=>data.json()).then((data)=>{

            if(data.status === true){
                setLoader(true);
                fetch(`${url}/blog?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
                    setLoader(false);
                    if(data.status === true){
                        setBlog(data.result);
                    }
                });
            }
    });
}


  return (<>{
    loader?<div className=' w-full flex justify-center mt-5 '><Loader /></div>:
    <Table to="/addBlog" name="Add Blog " rowNames={["#","Name","Blog Sub Category","Page Design","Image","For_Home","Actions"]} srcVal={src} srcFunc={search} data={blog} width='w-full'>
                {
                    blog.data.map(({_id, name, img, forHome, blogSubCategory}, index)=>{
                        return(
                            <Tr key={index}>
                                <Td>{((blog.previous.page*blog.previous.limit)+1)+index}</Td>
                                <Td>{name}</Td>
                                <Td>{blogSubCategory.name}</Td>
                                <Td><View to={"/blog/page/"+_id} /></Td>
                                <Td>{<Img >{img}</Img>}</Td>
                                <Td>
                                    <Select onChange={setForHome} value={forHome} id={_id}>
                                        <Option value="OFF" >OFF</Option>
                                        <Option value="ON" >ON</Option>
                                    </Select>
                                </Td>
                                <Td>
                                    <Edit to={"/editBlog/"+_id} />
                                    <Delete id={_id} click={delet} />
                                </Td>
                            </Tr>
                        );
                    })
                }
                
            </Table>
}</>)
}

export default AllBlog;