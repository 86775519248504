import React, {  useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Delete } from '../../../components/Button';
import Table, { Tr, Td } from '../../../components/Table';
import Loader from '../../../components/Loader';
import url from '../../../url'

function AllExclude({serviceId}) {

const [exclude, setExclude] = useState({data:[],next:{},previous:{}});
const [src, setSrc] = useState('');
const page = useSelector((state)=>state.page);
const limit = useSelector((state)=>state.limit);
const [loader, setLoader] = useState(false);



function delet(id){
    fetch(`${url}/exclude/${id}`,{method:'DELETE',mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
            if(src === ''){
                // setLoader(true);
                fetch(`${url}/exclude/search?page=${page}&limit=${limit}&search=${serviceId}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                    setLoader(false);
                    if(data.status === true){
                        setExclude(data.result);
                    }else{
                        setExclude({data:[],next:{},previous:{}})
                    }
                });
            }else{
                // setLoader(true);
                fetch(`${url}/exclude/search?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
                    setLoader(false);
                    if(data.status === true){
                        setExclude(data.result);
                    }
                });
                 
            }
        }else{

        }
    });
}


useEffect(()=>{
    if(src === ''){

        fetch(`${url}/exclude/search?page=${page}&limit=${limit}&search=${serviceId}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
            setLoader(false);
            if(data.status === true){
                setExclude(data.result);
            }else{
                setExclude({data:[],next:{},previous:{}})
            }
        });

    }

    return () => setExclude({data:[],next:{},previous:{}});

},[page,src, limit, serviceId]);

useEffect(()=>{
    if(src !== ''){
        fetch(`${url}/exclude/search?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setExclude(data.result);
            }
        });
    
    }

    return () => setExclude({data:[],next:{},previous:{}});
},[page, limit, src]);

function search(e){
    setSrc(e.target.value);
}


  return (<>{
    loader?<div className=' w-full flex justify-center mt-5 '><Loader /></div>:
    <Table to={"/addExclude/"+serviceId} name="Add Exclude" rowNames={["#","Excluded","Actions"]} srcVal={src} srcFunc={search} data={exclude} width='w-full'>
                {
                    exclude.data.map(({_id, name}, index)=>{
                        return(
                            <Tr key={index}>
                                <Td>{((exclude.previous.page*exclude.previous.limit)+1)+index}</Td>
                                <Td>{name}</Td>
                                <Td>
                                    {/* <Edit to={"/editInclude/"+_id} /> */}
                                    <Delete id={_id} click={delet} />
                                </Td>
                            </Tr>
                        );
                    })
                }
                
            </Table>
}</>)
}

export default AllExclude;