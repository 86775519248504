import React, { useState } from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from "react-router-dom";
import url from "../../url";
import { useEffect } from "react";

export const Editor = () => {
  const [state, setState] = useState({ value: null });
  const { id } = useParams();
  const navigate = useNavigate();

  const handleChange = (value) => {
    setState({ value });
  };
  

  function send(){
    fetch(`${url}/blog/${id}`,{
      method:"PUT",
      body: JSON.stringify({description:state.value}),
      credentials: 'include'
      }).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
          navigate(-1);
        }
    });
  }

  useEffect(()=>{

    fetch(`${url}/blog/${id}`,{
        method:"GET",
        credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
          setState({value: data.data.description});
        }
    });

  },[id])


  return (
    <div className="text-editor">
      <div className=" w-full h-auto bg-blue-200 py-5 px-10 relative">
        <button onClick={send} className=" px-2 bg-orange-600 text-white rounded-md">Save Page</button>
      </div>
      <EditorToolbar />
      <ReactQuill
        theme="snow"
        value={state.value}
        onChange={handleChange}
        placeholder={"Write something awesome..."}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

export default Editor;
