import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Form1 } from '../../components/Form';
import { Input1, Textarea, Select, Option } from  '../../components/Input';
import url from '../../url';
import { Alert2, AlertContainer } from '../../components/Alert';

function AddPackage() {
  const [value, setValue] = useState({name:'', description:'', subServiceId:'', unitId:'', purchasePrice:0, price: 0, discountPrice: 0, quantity: 0});
  const [serviceGroup, setServiceGroup] = useState([]);
  const [selectSG, setSelectSG] = useState("");
  const [service, setService] = useState([]);
  const [selectS, setSelectS] = useState("");
  const [subService, setSubService] = useState([]);
  const [unit, setUnit] = useState([]);
  const [alert, setAlert] = useState([]);
  const navigate = useNavigate();

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }



  useEffect(()=>{

    fetch(`${url}/serviceGroup?page=1&limit=0`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
        if(data.status === true){
            setServiceGroup(data.result.data);
        }
    });

  },[]);

  useEffect(()=>{

    fetch(`${url}/service/search?page=1&limit=0&search=${selectSG}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{

        if(data.status === true){
            setService(data.result.data);
        }else{
            setService([])
        }
    });
  },[selectSG]);

  useEffect(()=>{

    fetch(`${url}/subService/search?page=${1}&limit=${0}&search=${selectS}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
            setSubService(data.result.data);
        }else{
            setSubService([])
        }
    });
  },[selectS]);






  useEffect(()=>{

    fetch(`${url}/unit?page=1&limit=0`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
      if(data.status === true){;
        setUnit(data.result.data)
      }else{
        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
      }
  });

  },[]);

  function send(){
    fetch(`${url}/package`,{
      method:"POST",
      body: JSON.stringify(value),
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        navigate(-1);
      }else{
        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
      }
    });
  }


  return (
    <Form1 title="Add Package">
        <div className= " fixed top-16 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
        <Input1 onChange={set} name="name" placeholder="Enter package name:" value={value.name} lavel="Package name:" />
        {/* <Textarea onChange={set} name="description" placeholder="Enter package description:" value={value.description} lavel="Package description:" /> */}
        <Input1 onChange={set} type="number" name="purchasePrice" placeholder="Enter purchase price:" value={value.purchasePrice} lavel="Purchase Price:" />
        <Input1 onChange={set} type="number" name="price" placeholder="Enter price:" value={value.price} lavel="Sale Price:" />
        <Input1 onChange={set} type="number" name="discountPrice" placeholder="Enter discount price:" value={value.discountPrice} lavel="Discount price:" />
        <Input1 onChange={set} type="number" name="quantity" placeholder="Enter quantity:" value={value.quantity} lavel="Quantity:" />

        <Select onChange={set}  name="unitId" value={value.unitId}  id="input4"  lavel="Select unit:">
          <Option value="" disable={true}>Select a unit</Option>
          {
              unit.map((data, index)=>{
                  return(
                      <Option key={index} value={data._id}>{data.name}</Option>
                  )
              })
          }
        </Select>

        <Select onChange={(e)=>setSelectSG(e.target.value)}  name="SelectSG" value={selectSG}  id="SelectSG"  lavel="Select service group:">
          <Option value="" disable={true}>Select a service group</Option>
          {
              serviceGroup.map((data, index)=>{
                  return(
                      <Option key={index} value={data._id}>{data.name}</Option>
                  )
              })
          }
        </Select>

        <Select onChange={(e)=>setSelectS(e.target.value)}  name="SelectS" value={selectS}  id="SelectS"  lavel="Select service:">
          <Option value="" disable={true}>Select a service</Option>
          {
              service.map((data, index)=>{
                  return(
                      <Option key={index} value={data._id}>{data.name}</Option>
                  )
              })
          }
        </Select>

        <Select onChange={set}  name="subServiceId" value={value.subServiceId}  id="input4"  lavel="Select sub service:">
          <Option value="" disable={true}>Select a sub service</Option>
          {
              subService.map((data, index)=>{
                  return(
                      <Option key={index} value={data._id}>{data.name}</Option>
                  )
              })
          }
        </Select>
        <Button1 click={send} name="Save" />
    </Form1>

  )
}

export default AddPackage;