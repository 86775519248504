import React from 'react';
import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import url from '../../url';

function ViewBlog(){
    const [blog, setBlog] = useState({description:''});
    const { id } = useParams();


    useEffect(()=>{

        fetch(`${url}/blog/${id}`,{
            method:"GET",
            credentials: 'include'
        }).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setBlog(data.data);
            }
        });
    
    },[id]);

    return(
        <>
        <div className=" w-full h-auto p-10">
            <div className=' w-full border-2 mt-6 rounded-md border-dark-blue-1'>
                <div className=' w-full h-16 pr-4 flex  items-center justify-end bg-dark-blue-1'> <Link to={"/blog/page/create/"+id} ><button className=" rounded-3xl bg-cyan-200 hover:bg-dark-blue-1 border-2 border-cyan-200 hover:text-white   float-right mt-2 mr-2 px-2 py-1">Create Page</button></Link></div>
                <div className=' p-4'  dangerouslySetInnerHTML={{ __html: blog.description }}></div>
            </div>
        </div>
        </>
    )
}

export default ViewBlog;