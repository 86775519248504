import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button1 } from '../../../components/Button';
import { Form1 } from '../../../components/Form';
import { Input1, Select, Option, Textarea } from  '../../../components/Input';
import url from '../../../url';

function EditBlog() {
  const [value, setValue] = useState({name:'', blogSubCategoryId:'', shortDescription:''});
  const [blogCategory, setBlogCategory] = useState([]);
  const [blogCategoryId, setBlogCategoryId] = useState('');
  const [blogSubCategory, setBlogSubCategory] = useState([]);
  const navigate = useNavigate();
  const {id} = useParams();

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  function send(){
    fetch(`${url}/blog/${id}`,{
      method:"PUT",
      body: JSON.stringify(value),
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        navigate(-1);
      }
    });
  }

  useEffect(()=>{

    fetch(`${url}/blog/${id}`,{
        method:"GET",
        credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
          setValue({name: data.data.name, shortDescription:data.data.shortDescription, blogSubCategoryId: data.data.blogSubCategory._id});
        }
    });

  },[id]);


  useEffect(()=>{
    fetch(`${url}/blogSubCategory?page=1&limit=0`,{
      method:"GET",
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        setBlogSubCategory(data.result.data)
      }
    });

    fetch(`${url}/blogCategory?page=1&limit=0`,{
      method:"GET",
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        setBlogCategory(data.result.data)
      }
    });

  },[])

  function getSubCat(id){
    fetch(`${url}/blogSubCategory/search?page=1&limit=0&search=${id}`,{
      method:"GET",
      credentials: 'include'
      }).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
          setBlogSubCategory(data.result.data)
        }
    });
  }


  return (
    <Form1 title="Edit blog ">
        <Input1 onChange={set} name="name" placeholder="Enter blog  name:" value={value.name} lavel="Blog  name:" />
        <Select onChange={(e)=>{getSubCat(e.target.value); setBlogCategoryId(e.target.value)}}  name="blogCategoryId" value={blogCategoryId}  id="input4"  lavel="Select blog category:">
          <Option value="" disable={true}>Select a blog sub category</Option>
          {
              blogCategory.map((data, index)=>{
                  return(
                      <Option key={index} value={data._id}>{data.name}</Option>
                  )
              })
          }
        </Select>
        <Select onChange={set}  name="blogSubCategoryId" value={value.blogSubCategoryId}  id="input4"  lavel="Select blog sub category:">
          <Option value="" disable={true}>Select a blog sub category</Option>
          {
              blogSubCategory.map((data, index)=>{
                  return(
                      <Option key={index} value={data._id}>{data.name}</Option>
                  )
              })
          }
        </Select>
        <Textarea onChange={set} name="shortDescription" id="shortDescription" value={value.shortDescription} lavel="Short Description:" placeholder="Enter short description..."></Textarea>
        <Button1 click={send} name="Save" />
    </Form1>

  )
}
export default EditBlog;