import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Form1 } from '../../components/Form';
import { Input1, Textarea, Select, Option } from  '../../components/Input';
import url from '../../url';

function AddCoupon() {
  const [value, setValue] = useState({name:'', description:'', amount:0, discount:0, maxAmount:0, discountType:"TK"});
  const [serviceGroup, setServiceGroup] = useState([]);
  const [selectSG, setSelectSG] = useState("");
  const [service, setService] = useState([]);
  const [selectS, setSelectS] = useState("");
  const [subService, setSubService] = useState([]);
  const [subServiceS, setSubServiceS] = useState("");
  const [active, setActive] = useState("");
  const navigate = useNavigate();

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  useEffect(()=>{

    fetch(`${url}/serviceGroup?page=1&limit=0`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
        if(data.status === true){
            setServiceGroup(data.result.data);
        }
    });

  },[]);

  useEffect(()=>{

    fetch(`${url}/service/search?page=1&limit=0&search=${selectSG}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{

        if(data.status === true){
            setService(data.result.data);
        }else{
            setService([])
        }
    });
  },[selectSG]);

  useEffect(()=>{

    fetch(`${url}/subService/search?page=${1}&limit=${0}&search=${selectS}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
            setSubService(data.result.data);
        }else{
            setSubService([])
        }
    });
  },[selectS]);

  useEffect(()=>{
    if(selectSG !== "" && selectS !== "" && subServiceS !== ""){
      setActive("Sub Service");
    }else{
      if(selectSG !== "" && selectS !== ""){
        setActive("Service");
      }else{
        if(selectSG !== ""){
          setActive("Service Group");
        }else{
          setActive("Any");
        }
      }
    }
  },[selectSG, selectS, subServiceS]);

  // useEffect(()=>{
  //   if(value.discountType !== '%'){
  //     setValue({...value, maxAmount:value.discount});
  //   }
  // },[value]);

  function send(){

    fetch(`${url}/coupon`,{
        method:"POST",
        body: JSON.stringify({...value, serviceGroupId:selectSG, serviceId:selectS, subServiceId:subServiceS, activeIn:active}),
        credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
          navigate(-1);
        }
    });

  }


  return (
    <Form1 title="Add Coupon">
        <Input1 onChange={set} name="name" placeholder="Enter coupon name:" value={value.name} lavel="Coupon name:" />
        <Textarea onChange={set} name="description" placeholder="Enter coupon description:" value={value.description} lavel="Coupon description:" />
        <Select onChange={(e)=>setSelectSG(e.target.value)}  name="SelectSG" value={selectSG}  id="SelectSG"  lavel="Select service group:">
          <Option value="" disable={true}>Select a service group</Option>
          {
              serviceGroup.map((data, index)=>{
                  return(
                      <Option key={index} value={data._id}>{data.name}</Option>
                  )
              })
          }
        </Select>

        <Select onChange={(e)=>setSelectS(e.target.value)}  name="SelectS" value={selectS}  id="SelectS"  lavel="Select service:">
          <Option value="" disable={true}>Select a service</Option>
          {
              service.map((data, index)=>{
                  return(
                      <Option key={index} value={data._id}>{data.name}</Option>
                  )
              })
          }
        </Select>

        <Select  onChange={(e)=>setSubServiceS(e.target.value)}  name="subServiceId" value={value.subServiceId}  id="input4"  lavel="Select sub service:">
          <Option value="" disable={true}>Select a sub service</Option>
          {
              subService.map((data, index)=>{
                  return(
                      <Option key={index} value={data._id}>{data.name}</Option>
                  )
              })
          }
        </Select>
        <Select  onChange={(e)=>set(e)}  name="discountType" value={value.discountType}  id="discountType"  lavel="Select Discount Type:">
          <Option value="" disable={true}>Select a discount type</Option>
          <Option value="TK" disable={true}>TK</Option>
          <Option value="%" disable={true}>%</Option>
        </Select>
        <Input1 onChange={set} type="number" name="discount"  id="discount" placeholder="Discount amount:" value={value.discount}   lavel="Discount:" />
        {
          (value.discountType === '%')?<Input1 onChange={set} type="number" name="maxAmount"  id="maxAmount" placeholder="Max Discount:" value={value.maxAmount}   lavel="Max Discount:" />:<></>
        }
        <Input1 onChange={set} type="number" name="amount"  id="amount" placeholder="Enter minimum amount:" value={value.amount}   lavel="Minimum amount:" />
        <Button1 click={send} name="Save" />
    </Form1>

  )
}

export default AddCoupon;