import '../src/assets/css/style.css';
import './assets/css/custom.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import IsLogIn from './pages/IsLogIn';
import IsLogOut from './pages/IsLogOut';

import LogIn from './pages/Login';
import Home from './pages/Home';

import AddUser from './pages/user/AddUser';
import AllUser from './pages/user/AllUser';
// import EditArea from './pages/area/EditArea'

import AddArea from './pages/area/AddArea';
import AllArea from './pages/area/AllArea';
import EditArea from './pages/area/EditArea';

import AddBranch from './pages/branch/AddBranch';
import AllBranch from './pages/branch/AllBranch';
import EditBranch from './pages/branch/EditBranch';

import AddServiceGroup from './pages/serviceGroup/AddServicGroup';
import AllServiceGroup from './pages/serviceGroup/AllServiceGroup';
import EditServiceGroup from './pages/serviceGroup/EditServiceGroup';

import AddService from './pages/service/AddService';
import AllService from './pages/service/AllService';
import EditService from './pages/service/EditService';
import ViewService from './pages/service/ViewService';

import AddInclude from './pages/service/Include/AddInclude';
import AddExclude from './pages/service/exclude/AddExclude';
import AddFaq from './pages/service/faq/AddFaq';
import AddDetails from './pages/service/AddDetails';


import AddSubService from './pages/subService/AddSubService';
import AllSubService from './pages/subService/AllSubService';
import EditSubService from './pages/subService/EditSubService';

import AddPackage from './pages/package/AddPackage';
import AllPackage from './pages/package/AllPackage';
import EditPackage from './pages/package/EditPackage';
import ViewPackage from './pages/package/ViewPackage';
import AddPacDetails from './pages/package/AddDetails';

import AddPackagePhoto from './pages/package/AddPackagePhoto';
import PackagePhoto  from './pages/package/PackagePhoto';


import AddCoupon from './pages/coupon/AddCoupon';
import AllCoupon from './pages/coupon/AllCoupon';

import AddUnit from './pages/unit/AddUnit';
import AllUnit from './pages/unit/AllUnit';
import EditUnit from './pages/unit/EditUnit';

import AddProduct from './pages/product/AddProduct';
import AllProduct from './pages/product/AllProduct';
import EditProduct from './pages/product/EditProduct';

import AllWorker from './pages/worker/AllWorker';
import AddWorker from './pages/worker/AddWorker';
import EditWorker from './pages/worker/EditWorker';
import ViewWorker from './pages/worker/ViewWorker';
import WorkTodo from './pages/worker/WorkTodo';

import AddCustomer from './pages/customer/AddCustomer';
import AllCustomer from './pages/customer/AllCustomer';
import EditCustomer from './pages/customer/EditCustomer';
import Editor from './components/Editor';

import AddVendorGroup from './pages/vendorGroup/AddVendorGroup';
import AllVendorGroup from './pages/vendorGroup/AllVendorGroup';
import EditVendorGroup from './pages/vendorGroup/EditVendorGroup';

import AddVendor from './pages/vendor/AddVendor';
import AllVendor from './pages/vendor/AllVendor';
import EditVendor from './pages/vendor/EditVendor';

import AddBlogCategory from './pages/blog/category/AddBlogCategory';
import EditBlogCategory from './pages/blog/category/EditBlogCategory';
import AllBlogCategory from './pages/blog/category/AllBlogCategory';

import AddBlog from './pages/blog/post/AddBlog';
import EditBlog from './pages/blog/post/EditBlog';
import AllBlog from './pages/blog/post/AllBlog';

import ViewBlog from './pages/blog/ViewBlog';

import AddBlogSubCategory from './pages/blog/subCategory/AddBlogSubCategory';
import EditBlogSubCategory from './pages/blog/subCategory/EditBlogSubCategory';
import AllBlogSubCategory from './pages/blog/subCategory/AllBlogSubCategory';


import AllSale from './pages/sale/AllSale';
import EditSale from './pages/sale/EditSale';
import ViewSale from './pages/sale/ViewSale';
import Invoice from './pages/sale/Invoice';
import ViewVendor from './pages/sale/ViewVendor';

import AllVacancy from './pages/vacancy/AllVacancy';
import AddVacancy from './pages/vacancy/AddVacancy';
import EditVacancy from './pages/vacancy/EditVacancy';
import Description from './pages/vacancy/Description';
import AddDescription from './pages/vacancy/AddDescription';
import AllJobRequest from './pages/vacancy/AllJobRequest';
import SelfDetails from './pages/vacancy/SelfDetails';
import HireDetails from './pages/vacancy/HireDetails';
import WantDetails from './pages/vacancy/WantDetails';

import WorkTodoReport from './pages/report/WorkTodo';
import RequestService from './pages/report/RequestService';

import Editor2 from './pages/blog/Editor';




function App() {
  return (
    <Routes>
      
      <Route path='/' element={<Navigate to='/dashboard' />} />

      <Route path='/sale/invoice/:id' element={<Invoice />} />

      <Route path='/*' element={<IsLogOut />} >
        <Route path="logIn" element={ <LogIn />} />
      </Route>

      <Route path='/' element={<IsLogIn />} >
        <Route path='/' element={<Layout />} >
          <Route path='dashboard' element={<Home />} />

          <Route path='addUser' element={<AddUser />} />
          <Route path='user' element={<AllUser />} />

          
          <Route path='addArea' element={<AddArea />} />
          <Route path='area' element={<AllArea />} />
          <Route path='editArea/:id' element={<EditArea />} />

          <Route path='addBranch' element={<AddBranch />} />
          <Route path='Branch' element={<AllBranch />} />
          <Route path='editBranch/:id' element={<EditBranch />} />

          <Route path='addServiceGroup' element={<AddServiceGroup />} />
          <Route path='allServiceGroups' element={<AllServiceGroup />} />
          <Route path='editServiceGroup/:id' element={<EditServiceGroup />} />

          <Route path='addService' element={<AddService />} />
          <Route path='allService' element={<AllService />} />
          <Route path='editService/:id' element={<EditService />} />
          <Route path='viewService/:id' element={<ViewService />} />

          <Route path='addInclude/:id' element={<AddInclude />} />
          <Route path='addExclude/:id' element={<AddExclude />} />
          <Route path='addFaq/:id' element={<AddFaq />} />
          <Route path='service/addDetails/:id' element={<AddDetails />} />


          <Route path='addSubService' element={<AddSubService />} />
          <Route path='allSubService' element={<AllSubService />} />
          <Route path='editSubService/:id' element={<EditSubService />} />

          <Route path='addPackage' element={<AddPackage />} />
          <Route path='allPackage' element={<AllPackage />} />
          <Route path='editPackage/:id' element={<EditPackage />} />
          <Route path='viewPackage/:id' element={<ViewPackage />} />
          <Route path='package/addDetails/:id' element={<AddPacDetails />} />
          <Route path='package/photos/:id' element={<PackagePhoto />} />
          <Route path='package/addPhoto/:id' element={<AddPackagePhoto />} />

          <Route path='allCoupon' element={<AllCoupon />} />
          <Route path='addCoupon' element={<AddCoupon />} />

          <Route path='addUnit' element={<AddUnit />} />
          <Route path='allUnit' element={<AllUnit />} />
          <Route path='editUnit/:id' element={<EditUnit />} />

          <Route path='addProduct' element={<AddProduct />} />
          <Route path='allProduct' element={<AllProduct />} />
          <Route path='editProduct/:id' element={<EditProduct />} />

          <Route path='allWorkers' element={<AllWorker />} />
          <Route path='addWorker' element={<AddWorker />} />
          <Route path='editWorker/:id' element={<EditWorker />} />
          <Route path='worker/:id' element={<ViewWorker />} />
          <Route path='workTodo/:id' element={<WorkTodo />} />

          <Route path='allCustomer' element={<AllCustomer />} />
          <Route path='addCustomer' element={<AddCustomer />} />
          <Route path='editCustomer/:id' element={<EditCustomer />} />

          <Route path='allVendorGroup' element={<AllVendorGroup />} />
          <Route path='addVendorGroup' element={<AddVendorGroup />} />
          <Route path='editVendorGroup/:id' element={<EditVendorGroup />} />

          <Route path='allVendor' element={<AllVendor />} />
          <Route path='addVendor' element={<AddVendor />} />edit
          <Route path='editVendor/:id' element={<EditVendor />} />

          <Route path='allBlogCategory' element={<AllBlogCategory />} />
          <Route path='addBlogCategory' element={<AddBlogCategory />} />
          <Route path='editBlogCategory/:id' element={<EditBlogCategory />} />

          <Route path='allBlogSubCategory' element={<AllBlogSubCategory />} />
          <Route path='addBlogSubCategory' element={<AddBlogSubCategory />} />
          <Route path='editBlogSubCategory/:id' element={<EditBlogSubCategory />} />

          <Route path='blogs' element={<AllBlog />} />
          <Route path='addBlog' element={<AddBlog />} />
          <Route path='editBlog/:id' element={<EditBlog />} />
          
          <Route path='blog/page/:id' element={<ViewBlog />} />
          <Route path='blog/page/create/:id' element={<Editor2 />} />

          <Route path='allSale' element={<AllSale />} />
          <Route path='editSale/:id' element={<EditSale />} />
          <Route path='sale/:id' element={<ViewSale />} />
          <Route path='sale/:id/vendor' element={<ViewVendor />} />

          <Route path='vacancy' element={<AllVacancy />} />
          <Route path='addVacancy/' element={<AddVacancy />} />
          <Route path='editVacancy/:id' element={<EditVacancy />} />
          <Route path='vacancy/description/:id' element={<Description />} />
          <Route path='vacancy/addDescription/:id' element={<AddDescription />} />

          <Route path='jobRequest' element={<AllJobRequest />} />
          <Route path='jobRequest/SelfDetails/:id' element={<SelfDetails />} />
          <Route path='jobRequest/HireDetails/:id' element={<HireDetails />} />
          <Route path='jobRequest/WantDetails/:id' element={<WantDetails />} /> 


          <Route path='allTodo' element={<WorkTodoReport />} />
          <Route path='allServiceRequest' element={<RequestService />} />

          <Route path='editor' element={<Editor />} />
        </Route>
      </Route>

    </Routes>
  );
}

export default App;
