import React, {  useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Delete, Edit } from '../../components/Button';
import Table, { Tr, Td } from '../../components/Table';
import Loader from '../../components/Loader';
import url from '../../url';
import { useParams } from 'react-router-dom';

function PackagePhoto() {

const [serviceGroup, setServiceGroup] = useState({data:[],next:{},previous:{}});
const page = useSelector((state)=>state.page);
const limit = useSelector((state)=>state.limit);
const [loader, setLoader] = useState(true);
const { id } = useParams();



function delet(idd){
    fetch(`${url}/package/photo/${idd}`,{method:'DELETE',mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        if(data.status === true){

            setLoader(true);
            fetch(`${url}/package/photo/${id}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        
                setLoader(false);
                if(data.status === true){
                    setServiceGroup(data.result);
                }
            });    
            
        }else{

        }
    });
}

useEffect(()=>{

        setLoader(true);
        fetch(`${url}/package/photo/${id}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        
            setLoader(false);
            if(data.status === true){
                setServiceGroup(data.result);
            }
        });

    return () => setServiceGroup({data:[],next:{},previous:{}});

},[page,id, limit]);



  return (<>{
    loader?<div className=' w-full flex justify-center mt-5 '><Loader /></div>:
    <Table to={"/package/addPhoto/"+id} name="Add Photo" rowNames={["#","Photo", "Actions"]}  data={serviceGroup} width='w-full'>
        {
            serviceGroup.data.map(({_id, img}, index)=>{
                return(
                    <Tr key={index}>
                        <Td>{((serviceGroup.previous.page*serviceGroup.previous.limit)+1)+index}</Td>
                        <Td width='w-40 '><img className=' w-[200px] text-center block mx-auto' src={img} alt="" /></Td>
                        <Td>
                            <Delete id={_id} click={delet} />
                        </Td>
                    </Tr>
                );
            })
        }
        
    </Table>
}</>)
}

export default PackagePhoto;