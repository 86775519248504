import React, {  useEffect, useState } from 'react';
import Table, { Tr, Td } from '../../components/Table';
import Loader from '../../components/Loader';
import url from '../../url'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

function ViewWorker() {

const [workReport, setCategory] = useState({data:[],next:{},previous:{}});
const [src, setSrc] = useState('');
const page = useSelector((state)=>state.page);
const limit = useSelector((state)=>state.limit);
const [loader, setLoader] = useState(true);

const { id } = useParams();


useEffect(()=>{
    if(src === ''){
        setLoader(true);
        fetch(`${url}/workReport/${id}?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        
            setLoader(false);
            if(data.status === true){
                setCategory(data.result);
            }
        });
    }

    return () => setCategory({data:[],next:{},previous:{}});

},[page,src, id, limit]);

useEffect(()=>{
    if(src !== ''){
        fetch(`${url}/workReport/${id}?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setCategory(data.result);
            }
        });
    
    }

    return () => setCategory({data:[],next:{},previous:{}});
},[page, limit, id, src]);

function search(e){
    setSrc(e.target.value);
}


  return (<>{
    loader?<div className=' w-full flex justify-center mt-5 '><Loader /></div>:
    <Table to="" name="" rowNames={["#","Title","Description","Note", "Date"]} srcVal={src} srcFunc={search} data={workReport} width='w-full'>
                {
                    workReport.data.map(({_id, title, description, note, date}, index)=>{
                        const d = new Date(date);
                        const dat = d.toDateString();
                        return(
                            <Tr key={index}>
                                <Td>{((workReport.previous.page*workReport.previous.limit)+1)+index}</Td>
                                <Td>{title}</Td>
                                <Td width='w-40'>{description}</Td>
                                <Td width='w-40'>{note}</Td>
                                <Td width='w-40'>{dat}</Td>
                            </Tr>
                        );
                    })
                }
                
            </Table>
}</>)
}

export default ViewWorker;

