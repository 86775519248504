import React, {  useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Delete, Edit } from '../../components/Button';
import Filter from '../../components/Filter';
import Table, { Tr, Td } from '../../components/Table';
import { Option, Select } from '../../components/Input';
import Loader from '../../components/Loader';
import url from '../../url'

function AllSubService() {

const [subService, setSubService] = useState({data:[],next:{},previous:{}});
const [serviceGroup, setServiceGroup] = useState([]);
const [service, setService] = useState([]);
const [src, setSrc] = useState('');
const page = useSelector((state)=>state.page);
const limit = useSelector((state)=>state.limit);
const [loader, setLoader] = useState(true);
const [select, setSelect] = useState("");
const [selectSG, setSelectSG] = useState("");



function delet(id){
    fetch(`${url}/subService/${id}`,{method:'DELETE',mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
            if(src === ''){
                setLoader(true);
                fetch(`${url}/subService?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
                    setLoader(false);
                    if(data.status === true){
                        setSubService(data.result);
                    }
                });
            }else{
                setLoader(true);
                fetch(`${url}/subService/search?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
                    setLoader(false);
                    if(data.status === true){
                        setSubService(data.result);
                    }
                });
                 
            }
        }else{

        }
    });
}
useEffect(()=>{
    if(src === ''){
        setLoader(true);
        fetch(`${url}/subService?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        
            setLoader(false);
            if(data.status === true){
                setSubService(data.result);
            }
        });
    }

    return () => setSubService({data:[],next:{},previous:{}});

},[page,src, limit]);

useEffect(()=>{
    if(src !== ''){
        fetch(`${url}/subService/search?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setSubService(data.result);
            }
        });
    
    }

    return () => setSubService({data:[],next:{},previous:{}});
},[page, limit, src]);


useEffect(()=>{
    setLoader(true);
    fetch(`${url}/serviceGroup?page=1&limit=0`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
        setLoader(false);
        if(data.status === true){
            setServiceGroup(data.result.data);
        }
    });

},[]);


useEffect(()=>{

    setLoader(true);
    fetch(`${url}/service/search?page=${page}&limit=${limit}&search=${selectSG}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        setLoader(false);
        if(data.status === true){
            setService(data.result.data);
        }else{
            setService([])
        }
    });
},[selectSG]);



function search(e){
    setSrc(e.target.value);
}


useEffect(()=>{
    setLoader(true);
    fetch(`${url}/subService/search?page=${page}&limit=${limit}&search=${select}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        setLoader(false);
        if(data.status === true){
            setSubService(data.result);
        }else{
            setSubService({data:[],next:{},previous:{}})
        }
    });
},[select]);


  return (<>{
    loader?<div className=' w-full flex justify-center mt-5 '><Loader /></div>:
    <div>

        <Filter >
            <div className=' w-80 '>
                <Select onChange={(e)=>setSelectSG(e.target.value)} value={selectSG}  name="serviceId"  id="input4"  lavel="Select Service group :">
                    <Option value="" disable={true}>Select a service group</Option>
                    <Option value="" >All service group</Option>
                {
                    serviceGroup.map((data, index)=>{
                        return(
                            <Option key={index} value={data._id}>{data.name}</Option>
                        )
                    })
                }
                </Select>
            </div>

            <div className=' w-80 '>
                <Select onChange={(e)=>setSelect(e.target.value)} value={select}  name="serviceId"  id="input4"  lavel="Select Service :">
                    <Option value="" disable={true}>Select a service</Option>
                    <Option value="" >All service </Option>
                {
                    service.map((data, index)=>{
                        return(
                            <Option key={index} value={data._id}>{data.name}</Option>
                        )
                    })
                }
                </Select>
            </div>

        </Filter>

        <Table to="/addSubService" name="Add Sub Service" rowNames={["#","Name","Description", "Service", "Actions"]} srcVal={src} srcFunc={search} data={subService} width='w-full'>
                {
                    subService.data.map(({_id, name, service, description}, index)=>{
                        return(
                            <Tr key={index}>
                                <Td>{((subService.previous.page*subService.previous.limit)+1)+index}</Td>
                                <Td>{name}</Td>
                                <Td width='w-40'>{description}</Td>
                                <Td>{service.name}</Td>
                                <Td>
                                    <Edit to={"/editSubService/"+_id} />
                                    <Delete id={_id} click={delet} />
                                </Td>
                            </Tr>
                        );
                    })
                }
                
            </Table>
    </div>

}</>)
}

export default AllSubService;