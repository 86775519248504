import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TextEditor from '../../components/TextEditor';
import url from '../../url';
import { Alert2, AlertContainer } from '../../components/Alert';

function AddDescription() {

    const [dtl, setDetails] = useState({html:"",value:[]});
    const [alert, setAlert] = useState([]);
    const navigate = useNavigate();

    const { id } = useParams();

    function save(){

        fetch(`${url}/vacancy/${id}`,{
          method:'PUT',
          credentials: 'include',
          body:JSON.stringify({description:dtl.html})
        }).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                navigate(-1);
            }else{
                setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
            }
        });

    
    }

  return(
        <div className=' w-full p-4'>
            <div className= " fixed top-16 right-2 ">
                <AlertContainer>
                    {alert}
                </AlertContainer>
            </div>
            <div className=' w-full bg-cyan-300 p-10 rounded-md'>
                <TextEditor setDetails={setDetails} id={id}  save={save} />
            </div>
        </div>
        )
}

export default AddDescription;