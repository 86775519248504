import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Form1 } from '../../components/Form';
import { Input1, Option, Select} from  '../../components/Input';
import url from '../../url';

function AddBranch() {
  const [value, setValue] = useState({name:'', areaId:''});
  const [area, setArea] = useState([]);
  const navigate = useNavigate();

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  function send(){
    fetch(`${url}/branch`,{
      method:"POST",
      body: JSON.stringify(value),
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        navigate(-1);
      }
    });
  }

  useEffect(()=>{
    fetch(`${url}/area`,{
      method:"GET",
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        setArea(data.result.data)
      }
    });
  },[])



  return (
    <Form1 title="Add Branch">
        <Input1 onChange={set} name="name" placeholder="Enter branch name:" value={value.name} lavel="Branch name:" />
        <Select onChange={set} name="areaId"id="areaId" value={value.areaId} lavel="Select an area">
          <Option value="" >Please select an area.</Option>
          {
            area.map((data, index)=>{
              return(<Option key={index} value={data._id}>{data.name}</Option>)
            })
          }
        </Select>
        <Button1 click={send} name="Save" />
    </Form1>

  )
}

export default AddBranch;