import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import url from '../../url';
import AllExclude from './exclude/AllExclude';
import AllInclude from './Include/AllInclude';
import AllFaq from './faq/AllFaq';
import TextEditor from '../../components/TextEditor';

function ViewService() {

    const [service, setService] = useState({banner:{img:''}, image:{img:''}, details:{html:''}});
    // const [dtl, setDetails] = useState({html:"",value:[]});
    const { id } = useParams();


    useEffect(()=>{
        fetch(`${url}/service/${id}`).then((data)=> data.json()).then(data=>setService(data.data))
    },[id]);


    // function save(){

    //     fetch(`${url}/service/details/${id}`,{
    //       method:'PUT',
    //       body:JSON.stringify({details:dtl})
    //     }).then((data)=>data.json()).then((data)=> setService(data.data));

    
    //   }

  return (
    <div className=' w-full flex flex-col items-center justify-center p-10 '>
        <div><Link className=' bg-green-600 text-white px-5 py-2 rounded-full' to={"/editService/"+id}><i className="fa-solid fa-pen-to-square"></i></Link></div>
        <div className=' pb-4 pt-4'>
            <p><b>Name:</b> {service.name}</p>
            <p className=' text-justify'><b>Description:</b> {service.description}</p>
        </div>
        <div className=' w-1/2 text-2xl text-center'>
            <img src={service.banner.img} className=" w-full" alt="" />
            <h1>Banner</h1>
        </div>
        <div className=' w-1/2 text-2xl text-center mt-5'>
            <img src={service.image.img} className=" w-full" alt="" />
            <h1>Image</h1>
        </div>
        <AllInclude serviceId={id} />
        <AllExclude serviceId={id} />
        <AllFaq serviceId={id} />
        {/* <div className=' w-full bg-cyan-300 p-10 rounded-md'>
            <TextEditor setDetails={setDetails} id={id}  save={save} />
        </div> */}

        <div className=' w-full border-2 mt-6 rounded-md border-dark-blue-1'>
            <div className=' w-full h-16 pr-4 flex  items-center justify-end bg-dark-blue-1'> <Link to={"/service/addDetails/"+id} ><button className=" rounded-3xl bg-cyan-200 hover:bg-dark-blue-1 border-2 border-cyan-200 hover:text-white   float-right mt-2 mr-2 px-2 py-1">Add Details</button></Link></div>
            <div className=' p-4'  dangerouslySetInnerHTML={{ __html: service.details.html }}>

            </div>
        </div>
    </div>
  )
}

export default ViewService;