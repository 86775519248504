import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button1 } from '../../../components/Button';
import { Form1 } from '../../../components/Form';
import { Input1, Select, Option } from  '../../../components/Input';
import url from '../../../url';

function EditBlogSubCategory() {
  const [value, setValue] = useState({name:'', blogCategoryId:''});
  const [blogSubCategory, setBlogSubCategory] = useState([]);
  const navigate = useNavigate();
  const {id} = useParams();

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  function send(){
    fetch(`${url}/blogSubCategory/${id}`,{
      method:"PUT",
      body: JSON.stringify(value),
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        navigate(-1);
      }
    });
  }

  useEffect(()=>{

    fetch(`${url}/blogSubCategory/${id}`,{
        method:"GET",
        credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
          setValue({name: data.data.name, blogCategoryId:data.data.blogCategory._id});
        }
    });

  },[id]);

  useEffect(()=>{
    fetch(`${url}/blogCategory?page=1&limit=0`,{
      method:"GET",
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        setBlogSubCategory(data.result.data)
      }
    });
  },[])



  return (
    <Form1 title="Edit Blog Sub Category ">
        <Input1 onChange={set} name="name" placeholder="Enter blog sub category  name:" value={value.name} lavel="Blog sub category  name:" />
        <Select onChange={set}  name="blogCategoryId" value={value.blogCategoryId}  id="input4"  lavel="Select blog category:">
          <Option value="" disable={true}>Select a blog category</Option>
          {
              blogSubCategory.map((data, index)=>{
                  return(
                      <Option key={index} value={data._id}>{data.name}</Option>
                  )
              })
          }
        </Select>
        <Button1 click={send} name="Save" />
    </Form1>

  )
}
export default EditBlogSubCategory;