import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Form1 } from '../../components/Form';
import { Input1 } from  '../../components/Input';
import url from '../../url';

function AddVacancy() {
  const [value, setValue] = useState({name:'', deadline:'', vacancy:1});
  const navigate = useNavigate();

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  function send(){
    fetch(`${url}/vacancy`,{
      method:"POST",
      body: JSON.stringify(value),
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        navigate(-1);
      }
    });
  }


  return (
    <Form1 title="Add Vacancy">
        <Input1 onChange={set} name="name" placeholder="Enter vacancy name:" value={value.name} lavel="Vacancy name:" />
        <Input1 onChange={set} type="date" name="deadline" placeholder="Deadline for application:" value={value.deadline} lavel="Deadline :" />
        <Input1 onChange={set} type="number" name="vacancy" placeholder="Vacancy for application:" value={value.vacancy} lavel="Vacancy :" />
        <Button1 click={send} name="Save" />
    </Form1>

  )
}

export default AddVacancy;